<mat-progress-bar *ngIf="loadStatus === 'loading'" mode="query" color="accent"></mat-progress-bar>
<mat-progress-bar
  *ngIf="loadStatus === 'loadedFromIDB' || loadStatus === 'shortListLoaded'"
  mode="indeterminate"
  color="accent"
></mat-progress-bar>

<h1>Rechnungen</h1>

<mat-form-field>
  <mat-label>Finden - Beispiele: "Spülkasten", "Betrag: 324.3", "ID: 5554", "ID: 16125554"</mat-label>
  <input matInput [value]="searchTerm" #searchTermInput (keyup)="searchKeyup(searchTermInput.value)" />
</mat-form-field>

<ng-container *ngIf="bills$ | async; let bills">
  <section>
    <mat-card appearance="outlined" class="bill" *ngFor="let bill of bills">
      <mat-card-header>
        <mat-card-title (click)="editBill(bill)">{{ bill.uid }} | {{ bill.humanId }}</mat-card-title>
        <mat-card-subtitle (click)="editBill(bill)">
          <div>{{ bill.title }}, {{ bill.descriptionTitle }}</div>
          <div>{{ bill.addressView.commaSeparated }}</div>
        </mat-card-subtitle>
      </mat-card-header>
      <mat-card-content (click)="editBill(bill)">
        <span *ngIf="bill.workedAt">Arbeiten am: {{ bill.workedAt }} | </span>
        <span *ngIf="bill.billedAt">Verrechnet am: {{ bill.billedAt }} | </span>
        <span
          >{{ bill.totalNet | currency: 'CHF' }} netto |
          {{ bill.totalGross | currency: 'CHF' }} brutto</span
        >
        <div>{{ bill.ownerName }}, {{ bill.ordererName }}</div>
      </mat-card-content>
      <mat-card-actions>
        <button mat-icon-button type="button" (click)="editBill(bill)">
          <i class="material-icons">edit</i>
        </button>
        <button mat-icon-button type="button" routerLink="{{ bill.id }}/🖨">
          <i class="material-icons">print</i>
        </button>
        <button mat-icon-button type="button" (click)="generateQRBill(bill)">
          <i class="material-icons">qr_code_2</i>
        </button>
        <button mat-icon-button [matMenuTriggerFor]="menu">
          <i class="material-icons">more_vert</i>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="copyBill(bill)">
            <mat-icon>file_copy</mat-icon>
            <span>Kopieren</span>
          </button>
          <button mat-menu-item (click)="removeBill(bill)">
            <mat-icon>delete</mat-icon>
            <span>Löschen</span>
          </button>
        </mat-menu>
      </mat-card-actions>
    </mat-card>
  </section>

  <mat-card appearance="outlined">
    <mat-card-content *ngIf="bills.length === 0">KEINE RECHNUNGEN GEFUNDEN</mat-card-content>
    <mat-card-content *ngIf="bills.length === 1">{{ bills.length }} RECHNUNG</mat-card-content>
    <mat-card-content *ngIf="bills.length >= 2">{{ bills.length }} RECHNUNGEN</mat-card-content>
  </mat-card>

  <section class="load-more">
    <button *ngIf="bills.length >= searchLimit" mat-button type="button" (click)="loadMore()">
      MEHR LADEN
    </button>
  </section>
</ng-container>

<button routerLink="🆕" mat-fab color="primary" class="floating-create-button" type="submit">
  <mat-icon class="md-24" aria-label="Rechnung erstellen">add</mat-icon>
</button>
