<form *ngIf="form" [formGroup]="form" (ngSubmit)="onSubmit()" novalidate>
  <sb-articles-form [formGroup]="form" [articles]="bill?.articles"></sb-articles-form>

  <mat-card appearance="outlined">
    <mat-form-field *ngIf="!createNewBill">
      <mat-label>ID</mat-label>
      <input matInput formControlName="humanId" />
    </mat-form-field>

    <mat-form-field>
      <mat-label>Art (Rechnung / Offerte / Spezial)</mat-label>
      <input matInput formControlName="billType" [matAutocomplete]="billTypeAuto" />
    </mat-form-field>
    <mat-autocomplete #billTypeAuto="matAutocomplete">
      <mat-option *ngFor="let option of autocompleteOptions['billType'] | async" [value]="option">
        {{ option }}
      </mat-option>
    </mat-autocomplete>

    <mat-form-field>
      <mat-label>Titel Rechnung</mat-label>
      <input matInput formControlName="title" [matAutocomplete]="titleAuto" />
    </mat-form-field>
    <mat-autocomplete (optionSelected)="titleSelected($event)" #titleAuto="matAutocomplete">
      <mat-option *ngFor="let option of autocompleteOptions['title'] | async" [value]="option">
        {{ option }}
      </mat-option>
    </mat-autocomplete>

    <mat-form-field>
      <mat-label>Adresse</mat-label>
      <textarea
        matInput
        formControlName="address"
        cdkTextareaAutosize
        [matAutocomplete]="addressAuto"
      ></textarea>
    </mat-form-field>
    <mat-autocomplete (optionSelected)="addressSelected($event)" #addressAuto="matAutocomplete">
      <mat-option *ngFor="let option of autocompleteOptions['address'] | async" [value]="option">
        {{ option }}
      </mat-option>
    </mat-autocomplete>

    <mat-form-field>
      <mat-label>Eigentümer</mat-label>
      <input matInput formControlName="ownerName" [matAutocomplete]="ownerNameAuto" />
    </mat-form-field>
    <mat-autocomplete #ownerNameAuto="matAutocomplete">
      <mat-option *ngFor="let option of autocompleteOptions['ownerName'] | async" [value]="option">
        {{ option }}
      </mat-option>
    </mat-autocomplete>

    <mat-form-field>
      <mat-label>Auftraggeber/Nr</mat-label>
      <input matInput formControlName="ordererName" [matAutocomplete]="ordererNameAuto" />
    </mat-form-field>
    <mat-autocomplete #ordererNameAuto="matAutocomplete">
      <mat-option *ngFor="let option of autocompleteOptions['ordererName'] | async" [value]="option">
        {{ option }}
      </mat-option>
    </mat-autocomplete>
  </mat-card>

  <mat-card appearance="outlined">
    <mat-form-field>
      <mat-label>Titel Beschreibung</mat-label>
      <input matInput formControlName="descriptionTitle" [matAutocomplete]="descriptionTitleAuto" />
    </mat-form-field>
    <mat-autocomplete
      (optionSelected)="descriptionTitleSelected($event)"
      #descriptionTitleAuto="matAutocomplete"
    >
      <mat-option
        *ngFor="let option of autocompleteOptions['descriptionTitle'] | async"
        [value]="option"
      >
        {{ option }}
      </mat-option>
    </mat-autocomplete>

    <mat-form-field>
      <mat-label>Arbeitsbeschreibung</mat-label>
      <textarea
        matInput
        formControlName="description"
        cdkTextareaAutosize
        [matAutocomplete]="descriptionAuto"
      ></textarea>
    </mat-form-field>
    <mat-autocomplete #descriptionAuto="matAutocomplete">
      <mat-option *ngFor="let option of autocompleteOptions['description'] | async" [value]="option">
        {{ option }}
      </mat-option>
    </mat-autocomplete>
  </mat-card>

  <mat-card appearance="outlined">
    <mat-form-field>
      <mat-label
        >Ausgeführte Arbeiten am (Freitext oder yyyy-mm-dd, yyyy-mm-dd, ... als Datum)</mat-label
      >
      <input matInput formControlName="workedAt" />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Auftragsdatum (yyyy-mm-dd)</mat-label>
      <input matInput formControlName="orderedAt" />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Verrechnet am (yyyy-mm-dd), wird beim Drucken automatisch gesetzt falls leer</mat-label>
      <input matInput formControlName="billedAt" />
    </mat-form-field>
  </mat-card>

  <mat-card appearance="outlined" class="numbers">
    <mat-form-field>
      <mat-label>Skonto in %</mat-label>
      <input matInput formControlName="cashback" />
    </mat-form-field>
    <mat-form-field>
      <mat-label>MWST in %</mat-label>
      <input matInput formControlName="vat" />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Rabatt</mat-label>
      <input matInput formControlName="discount" />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Zahlbar innert ... Tagen</mat-label>
      <input matInput formControlName="paymentDeadlineInDays" />
    </mat-form-field>
  </mat-card>

  <button mat-raised-button color="primary" type="submit">SPEICHERN</button>

  <button mat-raised-button class="abort-button" type="button" (click)="abort()">ABBRECHEN</button>

  <button mat-fab color="primary" class="floating-save-button" type="submit">
    <mat-icon class="md-24" aria-label="Speichern">save</mat-icon>
  </button>
</form>
