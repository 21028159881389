<ng-container *ngIf="formGroup" [formGroup]="formGroup">
  <mat-card appearance="outlined" formArrayName="articles">
    <mat-card-header>
      <mat-card-title>Artikel</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div
        *ngFor="let article of articlesForm.controls; let i = index"
        [formGroupName]="i"
        class="article"
        [ngClass]="isGap(i) ? 'gap' : ''"
      >
        <div class="form-field-wrapper catalogId">
          <mat-form-field>
            <mat-label>Katalognr.</mat-label>
            <input matInput formControlName="catalogId" />
          </mat-form-field>
        </div>
        <div class="form-field-wrapper description">
          <mat-form-field>
            <mat-label>Beschreibung</mat-label>
            <input
              #articleDescription
              matInput
              (focus)="articleFocus(articleDescription.value)"
              formControlName="description"
              [matAutocomplete]="articleDescriptionAuto"
            />
          </mat-form-field>
          <mat-autocomplete
            (optionSelected)="autocompleteSelected(i, $event.option.value)"
            [displayWith]="displayDescription"
            #articleDescriptionAuto="matAutocomplete"
          >
            <mat-option *ngFor="let option of autocompleteOptions | async" [value]="option">
              {{ option.displayValue }}
            </mat-option>
          </mat-autocomplete>
        </div>
        <div class="form-field-wrapper dimension">
          <mat-form-field>
            <mat-label>Dimension</mat-label>
            <input matInput formControlName="dimension" />
          </mat-form-field>
        </div>
        <div class="form-field-wrapper price">
          <mat-form-field>
            <mat-label>Preis</mat-label>
            <input matInput formControlName="price" />
          </mat-form-field>
        </div>
        <div class="form-field-wrapper amount">
          <mat-form-field>
            <mat-label>Anzahl</mat-label>
            <input matInput formControlName="amount" />
          </mat-form-field>
        </div>
        <div class="form-field-wrapper actions">
          <button mat-icon-button type="button" (click)="removeAt(i)">
            <i class="material-icons">delete</i>
          </button>
          <button *ngIf="i !== 0" mat-icon-button type="button" (click)="moveUp(i)">
            <i class="material-icons">arrow_upward</i>
          </button>
        </div>
      </div>
    </mat-card-content>
    <mat-card-actions>
      <button mat-button type="button" (click)="addNewArticles(1)">1 ARTIKEL HINZUFÜGEN</button>
      <button mat-button type="button" (click)="addNewArticles(5)">5 ARTIKEL HINZUFÜGEN</button>
      <button mat-button type="button" (click)="addNewArticles(10)">10 ARTIKEL HINZUFÜGEN</button>
    </mat-card-actions>
  </mat-card>
</ng-container>
